import React from "react";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  useAdvancedMarkerRef,
} from "@vis.gl/react-google-maps";
import { Dialog } from "@mui/material";
import { ClickAwayListener } from "@mui/base/ClickAwayListener";

export default function SupplierLocation({
  open,
  onClose,
  onConfirm,
  latitude,
  longtitude,
}) {
  const [latValue, setLatValue] = React.useState(35.6812405);
  const [longValue, setLongValue] = React.useState(139.7646955);
  // const [markerRef, marker] = useAdvancedMarkerRef();
  const API_KEY = import.meta.env.VITE_GOOGLE_MAP_API_KEY;
  const MAP_LANGUAGE = import.meta.env.VITE_GOOGLE_MAP_LANGUAGE;
  const [addressArray, setAddressArray] = React.useState([]);
  const [postalCode, setPostalCode] = React.useState("");

  React.useEffect(() => {
    setLatValue(latitude);
    setLongValue(longtitude);
  }, [latitude, longtitude]);

  React.useEffect(() => {
    if (latValue && longValue) {
      fetAddress();
    }
  }, [latValue, longValue]);
  const handleLocation = (ev) => {
    setLatValue(ev.detail.latLng.lat);
    setLongValue(ev.detail.latLng.lng);
  };

  const handleClose = (event, reason) => {
    if (reason && reason === "backdropClick" && "escapeKeyDown") return;
    onClose(false);
  };

  const fetAddress = async () => {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?latlng=${encodeURIComponent(
        latValue
      )},${encodeURIComponent(longValue)}&language=ja&key=${encodeURIComponent(
        API_KEY
      )}`
    );
    const json = await response.json();
    if (json?.results && json?.results?.length > 0) {
      let arr = new Array();
      json.results.map((item) => {
        arr.push(item.formatted_address);
      });
      setAddressArray(arr);
      if (json?.results[0].formatted_address.includes("〒")) {
        let indexPostal = json?.results[0].formatted_address.indexOf("〒");
        let postalCode = json?.results[0].formatted_address.substring(
          indexPostal + 1,
          12
        );
        setPostalCode(postalCode);
      } else {
        setPostalCode("");
      }
    }
  };

  const handleClickAway = () => {
    onClose();
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="flex-col justify-start items-start flex">
          <ClickAwayListener onClickAway={handleClickAway}>
            <div className="w-[1146px] h-[800px] bg-white rounded-lg shadow flex-col justify-start items-center gap-10 flex relative">
              {/* <div className="w-[1146px] h-[800px] text-center text-zinc-800 text-lg font-normal w-screen h-screen flex justify-center"> */}
              <APIProvider apiKey={API_KEY} language={MAP_LANGUAGE}>
                <Map
                  onClick={(ev) => handleLocation(ev)}
                  style={{ width: "1146px", height: "800px" }}
                  defaultCenter={{
                    lat: latValue > 0 ? latValue : 35.6806779,
                    lng: longValue > 0 ? longValue : 139.7662969,
                  }}
                  defaultZoom={14}
                  gestureHandling={"greedy"}
                  disableDefaultUI={false}
                  mapId={"befa52f039122016"}
                  // ref={markerRef}
                >
                  <AdvancedMarker
                    // ref={markerRef}
                    position={{
                      lat: latValue > 0 ? latValue : 35.6806779,
                      lng: longValue > 0 ? longValue : 139.7662969,
                    }}
                    title={location.name}
                  ></AdvancedMarker>
                </Map>
              </APIProvider>
              {/* </div> */}
              <div className="absolute justify-start z-50 items-start gap-5 inline-flex bottom-[10px]">
                <div className="justify-start items-start flex mb-[20px]">
                  <div
                    className="w-28 h-8 px-3 pb-1 bg-white rounded-3xl border border-[#748bbe] justify-center items-center gap-2.5 flex"
                    onClick={() => onClose()}
                  >
                    <div className="w-28 h-3 relative">
                      <div className="bg-white/opacity-60  left-[6px] top-0 absolute text-center text-[#748bbe] text-sm font-bold  leading-3">
                        <button>キャンセル</button>
                      </div>
                      <div className="w-28 h-px left-0 top-[6.50px] absolute"></div>
                    </div>
                  </div>
                </div>
                <div className="justify-start items-start flex">
                  <div
                    className="w-28 h-8 px-3 pt-0.5 pb-1 bg-blue-500 rounded-3xl justify-center items-center gap-2.5 flex"
                    onClick={() => {
                      onConfirm(latValue, longValue, addressArray, postalCode);
                    }}
                  >
                    <div className="w-28 h-3 relative">
                      <div className="left-[16px] top-0 absolute text-center text-white text-sm font-bold  leading-3">
                        <button className="hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300">
                          場所を選択
                        </button>
                      </div>
                      <div className="w-28 h-px left-0 top-[6.50px] absolute"></div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </ClickAwayListener>
        </div>
      </div>
    </Dialog>
  );
}
