import React from "react";
import polygon2 from "../../../assets/polygon2.png";
import polygon1 from "../../../assets/polygon1.png";
import UserDetail from "./UserDetail";
import UserRegistration from "./UserRegistration";
import DialogCompleted from "../../../components/DialogCompleted";
import DeleteUserConfirm from "./DeleteUserConfirm";
import { useDispatch, useSelector } from "react-redux";
import { userActions } from "../../../actions";
import ReactPaginate from "react-paginate";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import Moment from "moment";
import { Tooltip } from "@mui/material";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import $ from "jquery";

export default function TabUserApprove({ openModal }) {
  const [userId, setUserId] = React.useState();
  const [userIds, setUserIds] = React.useState([]);
  const [modalDetailOpen, setModalDetailOpen] = React.useState(false);
  const [modalRegistOpen, setModalRegistOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(0);
  const [currentLimit, setCurrentLimit] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [filter, setFilter] = React.useState({
    id: 1,
    approve_status: 1,
    name: 1,
    company_working_at_name: 1,
    company_working_at_phone_number: 1,
    email: 1,
    gender: 1,
    age: 1,
    created_at: 1,
  });
  const [body, setBody] = React.useState({
    number_of_records_per_page: 10,
    page_number: 1,
    orderBy: "id",
    direction: "DESC",
  });

  const dispatch = useDispatch();
  const users = useSelector((state) => state.user.users);

  const gender = ["男性", "女性", "その他", "回答しない"];

  const getUsser = async () => {
    await dispatch(userActions.getUsers(searchValue, body));
  };

  React.useEffect(() => {
    setUserIds([]);
    getUsser();
  }, []);

  React.useEffect(() => {}, [users.items]);

  React.useEffect(() => {
    setUserIds([]);
    dispatch(userActions.getUsers(searchValue, body));
  }, [searchValue, body]);

  const handleCloseDialog = () => {
    setModalRegistOpen(false);
    openModal(false);
  };

  const handleDeleteDialogOpen = () => {
    if (userIds.length > 0) {
      setConfirmDeleteOpen(true);
      openModal(true);
    }
  };

  const handleDelete = async () => {
    if (userId) {
      var data = {
        ids: [userId],
      };
    } else if (userIds.length > 0) {
      var data = {
        ids: userIds,
      };
    }

    await dispatch(userActions.delete(data));
    setConfirmDeleteOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess(data.ids.length + "件のユーザー削除が\n完了しました");
    openModal(true);
    setUserId();
    setTimeout(() => {
      setUserIds([]);
    }, 3000);
    setSearchValue("");
    getUsser();
  };

  const handleChangeValue = (e) => {
    if (e.target.checked) {
      setUserIds([...userIds, e.target.value]);
    } else {
      var array = [...userIds];
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        setUserIds(array);
      }
    }
  };

  const handleCreate = () => {
    setSearchValue("");
    setModalRegistOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess("1件のユーザー登録が\n完了しました");
    openModal(true);
    getUsser();
  };

  const handleUpdate = () => {
    setSearchValue("");
    setModalDetailOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess("1件のユーザー情報の編集が\n完了しました");
    openModal(true);
    getUsser();
  };

  const handleOpenDetail = (id) => {
    setUserId(id);
    setModalDetailOpen(true);
    openModal(true);
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    setBody((body) => ({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: event.selected + 1,
    }));
  };

  const handleSearch = (value) => {
    setSearchValue(event.target.value);
    setBody({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: 1,
    });
    setCurrentPage(0);
    // dispatch(userActions.getUsers(value, body));
  };

  const onChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
    setBody({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: 1,
    });
    setCurrentPage(0);
  };

  const onChangeLimit = (event) => {
    setCurrentLimit(event.target.value);
    setCurrentPage(0);
    setBody((body) => ({
      ...body,
      number_of_records_per_page: event.target.value,
      page_number: 1,
    }));
  };

  const handleOpenRegisterd = (e) => {
    e.preventDefault();
    setModalRegistOpen(true);
    openModal(true);
  };

  const optionLimitPage = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "1000", label: "1000" },
  ];

  const handleFilter = (field) => {
    let direction;
    if (field == "id") {
      filter.id == 0 ? (filter.id = 1) : (filter.id = 0);
      filter.id == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "approve_status") {
      filter.approve_status == 0
        ? (filter.approve_status = 1)
        : (filter.approve_status = 0);
      filter.approve_status == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "name") {
      filter.name == 0 ? (filter.name = 1) : (filter.name = 0);
      filter.name == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "company_working_at_name") {
      filter.company_working_at_name == 0
        ? (filter.company_working_at_name = 1)
        : (filter.company_working_at_name = 0);
      filter.company_working_at_name == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field == "company_working_at_phone_number") {
      filter.company_working_at_phone_number == 0
        ? (filter.company_working_at_phone_number = 1)
        : (filter.company_working_at_phone_number = 0);
      filter.company_working_at_phone_number == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field == "email") {
      filter.email == 0 ? (filter.email = 1) : (filter.email = 0);
      filter.email == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "gender") {
      filter.gender == 0 ? (filter.gender = 1) : (filter.gender = 0);
      filter.gender == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "age") {
      filter.age == 0 ? (filter.age = 1) : (filter.age = 0);
      filter.age == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field == "created_at") {
      filter.created_at == 0
        ? (filter.created_at = 1)
        : (filter.created_at = 0);
      filter.created_at == 0 ? (direction = "ASC") : (direction = "DESC");
    }
    setFilter(filter);
    setBody((body) => ({
      ...body,
      orderBy: field,
      direction: direction,
    }));
  };

  $(function () {
    $("#limit").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
    $("#limit option").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });
  $("select").on("change", function () {
    if ($(this).val() == "") $(this).css({ color: "#aaa" });
    else $(this).css({ color: "#000" });
  });

  return (
    <>
      <div className="relative flex flex-row 2xl:w-full h-[75px] bg-zinc-100 2xl:w-full justify-between">
        <div className="2xl:w-[30%] xl:w-[30%] lg:w-[30%]">
          <button
            type="button"
            onClick={(e) => handleOpenRegisterd(e)}
            className="w-[81px] h-6 mt-[29px] ml-[10px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            新規登録
          </button>
          <button
            disabled={userIds.length <= 0}
            onClick={() => handleDeleteDialogOpen(true)}
            className={`${
              userIds.length > 0
                ? "border-blue-900 text-blue-900"
                : "border-gray-300 text-gray-300"
            } w-[81px] h-6 mt-[29px] ml-[9px] bg-white rounded-[50px] border text-center text-xs font-bold hover:bg-violet-100 active:bg-violet-100 focus:outline-none focus:ring focus:ring-violet-300`}
          >
            一括削除
          </button>
        </div>

        <div className="relative flex flex-col 2xl:w-[50%] xl:w-[50%] lg:w-[50%] h-[51px] mt-[9px]">
          <span className="text-black text-sm font-normal ">
            キーワード検索
          </span>
          <input
            placeholder="キーワードを入力…"
            className="p-2 2xl:w-[100%] h-[27px] bg-white rounded-[5px] text-black/opacity-60 text-xs font-normal"
            value={searchValue}
            onChange={onChangeSearchValue}
          />
          <button
            onClick={() => {
              handleSearch(searchValue);
            }}
            className="absolute flex justify-center right-[5px] text-center flex top-[24px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
          >
            検索
          </button>
        </div>
      </div>

      <div className="relative flex xl:w-full lg:w-full h-[35px] mt-[16px] bg-zinc-100 items-center 2xl:w-full">
        <div className="flex 2xl:w-[6.15%] xl:w-[6.15%] lg:w-[6.15%] justify-center items-center lg:text-xs">
          <span className="">#</span>
          <img
            onClick={() => handleFilter("id")}
            className="w-[11px] h-[11px] 2xl:ml-[10px] xl:ml-[7px] lg:ml-[5px]"
            src={filter.id == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[5.3%] xl:w-[5.3%] lg:w-[5.3%] justify-start items-center">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            認証
          </span>
          <img
            onClick={() => handleFilter("approve_status")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px] "
            src={filter.approve_status == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[12.3%] xl:w-[12.3%] lg:w-[12.3%] justify-start items-center">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            ユーザー名
          </span>
          <img
            onClick={() => handleFilter("name")}
            className=" w-[11px] h-[11px] 2xl:ml-[20px] xl:ml-[10px] lg:ml-[7px]"
            src={filter.name == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[13.2%] xl:w-[13.2%] lg:w-[13.2%] justify-start items-center">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            在籍企業名
          </span>
          <img
            onClick={() => handleFilter("company_working_at_name")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px]"
            src={filter.company_working_at_name == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex 2xl:w-[11%] xl:w-[11%] lg:w-[11%] justify-start items-center truncate">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            在籍企業電話番号
          </span>
          <img
            onClick={() => handleFilter("company_working_at_phone_number")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px]"
            src={
              filter.company_working_at_phone_number == 0 ? polygon2 : polygon1
            }
          />
        </div>
        <div className="flex 2xl:w-[19%] xl:w-[19%] lg:w-[19%] justify-start items-center">
          <span className=" text-center text-black 2xl:text-sm font-normal lg:text-xs">
            メールアドレス
          </span>
          <img
            onClick={() => handleFilter("email")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px]"
            src={filter.email == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex h-full 2xl:w-[9.7%] xl:w-[9.7%] lg:w-[9.7%] justify-start items-center">
          <span className=" text-center text-black 2xl:text-sm font-normal lg:text-xs">
            属性
          </span>
          <img
            onClick={() => handleFilter("gender")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px] "
            src={filter.gender == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex h-full 2xl:w-[7%] xl:w-[7%] lg:w-[7%] justify-start items-center">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            年齢
          </span>
          <img
            onClick={() => handleFilter("age")}
            className=" 2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px]"
            src={filter.age == 0 ? polygon2 : polygon1}
          />
        </div>
        <div className="flex h-full justify-start items-center ">
          <span className="text-center text-black 2xl:text-sm font-normal lg:text-xs">
            アカウント登録日
          </span>
          <img
            onClick={() => handleFilter("created_at")}
            className="2xl:ml-[2px] xl:ml-2px w-[11px] h-[11px]"
            src={filter.created_at == 0 ? polygon2 : polygon1}
          />
        </div>
      </div>
      <div
        className={classNames(
          "overflow-y-scroll 2xl:w-full xl:w-full lg:w-full max-h-[543px] 2xl:h-[80%] xl:h-[65%] lg:h-[65%]"
          // {
          //   "max-h-[80%] 2xl:h-[80%] xl:h-[80%] lg:h-[80%]":
          //     users.items && users.items.data.users.length > 7,
          // }
        )}
      >
        {users.items && users.items.data.users.length > 0 ? (
          users.items.data.users.map((item, i) => {
            var phone = "" + item.company_working_at_phone_number;
            var formated_phone =
              phone.substring(0, 3) +
              "-" +
              phone.substring(3, 7) +
              "-" +
              phone.substring(7, 11) +
              phone.substring(11, phone.length);
            // var formated_phone = "" + item.company_working_at_phone_number?.replace(/(\d{2})(\d{4})(\d{4})/,"$1-$2-$3");;
            return (
              <div
                key={item.id + "_" + i}
                className="relative flex 2xl:w-full xl:w-full lg:w-full 2xl:h-[72px] xl:h-[72px] lg:h-[72px] items-center border-b-[1px] border-zinc-300"
              >
                <div className="flex justify-center 2xl:w-[2.65%] xl:w-[2.65%] lg:[2.65%] truncate">
                  <input
                    className="w-3.5 h-3.5"
                    type="checkbox"
                    value={item.id}
                    onChange={(e) => handleChangeValue(e)}
                  />
                </div>

                <div
                  className="flex 2xl:w-[97.35%] xl:w-[97.35%] lg:w-[97.35%] 2xl:h-[72px] items-center cursor-pointer"
                  onClick={() => handleOpenDetail(item.id)}
                >
                  <Tooltip title={"100" + item.id} placement="bottom-start">
                    <div
                      className="2xl:w-[3.5%] xl:w-[3.5%] lg:w-[5%] text-zinc-800 text-sm leading-3 truncate"
                      style={{ fontSize: "11px" }}
                    >
                      {"100" + item.id}
                    </div>
                  </Tooltip>
                  <div className="2xl:w-[5.7%] xl:w-[5.3%] lg:w-[5.3%] text-black text-sm font-normal truncate">
                    {item.approve_status == 0 ? (
                      <Tooltip title={"非承認"} placement="bottom-start">
                        <span
                          className="text-red-500"
                          style={{ fontSize: "11px" }}
                        >
                          非承認
                        </span>
                      </Tooltip>
                    ) : (
                      <Tooltip title={"承認"} placement="bottom-start">
                        <span
                          className="text-black"
                          style={{ fontSize: "11px" }}
                        >
                          承認
                        </span>
                      </Tooltip>
                    )}
                  </div>
                  <div className="2xl:w-[12.7%] xl:w-[12.7%] lg:w-[13%] text-black text-sm font-normal truncate pr-[5px]">
                    <Tooltip title={item.name} placement="bottom-start">
                      {/* <span>
                        {item.name.length <= 7
                          ? item.name
                          : item.name.substring(0, 7) + "..."}
                      </span> */}
                      <span>{item.name}</span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[13.7%] xl:w-[13.7%] lg:w-[14%] text-black text-sm font-normal truncate pr-[5px]">
                    <Tooltip
                      title={item.company_working_at_name}
                      placement="bottom-start"
                    >
                      {/* <span>
                        {item.company_working_at_name.length <= 10
                          ? item.company_working_at_name
                          : item.company_working_at_name.substring(0, 10) +
                            "..."}
                      </span> */}
                      <span>{item.company_working_at_name}</span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[11.5%] xl:w-[11.5%] lg:w-[11.5%] text-black text-sm font-normal truncate pr-[5px]">
                    <Tooltip title={item.company_working_at_phone_number} arrow>
                      <span>
                        {/* {formated_phone.length <= 15
                          ? formated_phone
                          : formated_phone.substring(0, 15) + "..."} */}
                        {item.company_working_at_phone_number}
                      </span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[19.7%] xl:w-[19.7%] lg:w-[19.7%] text-black text-sm font-normal truncate pr-[5px]">
                    <Tooltip title={item.email} placement="bottom-start">
                      {/* <span>
                        {item.email.length <= 15
                          ? item.email
                          : item.email.substring(0, 15) + "..."}
                      </span> */}
                      <span>{item.email}</span>
                    </Tooltip>
                  </div>
                  <div className="2xl:w-[10%] xl:w-[10%] lg:w-[10%] text-black text-sm font-normal justify-end truncate">
                    {gender[item.gender - 1]}
                  </div>
                  <div className="2xl:w-[7.5%] xl:w-[7%] lg:w-[7%] text-black text-sm font-normal truncate">
                    <Tooltip
                      title={
                        item.age
                          ? item.age + "0 - " + item.age + "9歳"
                          : "未回答"
                      }
                      placement="bottom-start"
                    >
                      <span>{item.age
                        ? item.age + "0 - " + item.age + "9歳"
                        : "未回答"}</span>
                    </Tooltip>
                  </div>
                  <div className=" 2xl:w-[8%] xl:w-[8%] lg:w-[8%] text-black text-sm font-normal truncate">
                    <Tooltip
                      title={
                        item.created_at
                          ? Moment(item.created_at).format("YYYY/MM/DD")
                          : ""
                      }
                      placement="bottom-start"
                    >
                      <span>{item.created_at
                        ? Moment(item.created_at).format("YYYY/MM/DD")
                        : ""}</span>
                    </Tooltip>
                  </div>
                  <button
                    onClick={() => handleOpenDetail(item.id)}
                    href="#"
                    className="text-black text-sm font-normal flex text-blue-900 text-sm font-bold justify-end ml-[20px]"
                  >
                    詳細
                  </button>
                </div>
              </div>
            );
          })
        ) : (
          <>
            <div className="flex w-[80%] 2xl:w-full xl:w-full lg:w-full h-full justify-center items-center">
              {users.loading ? (
                <div className="text-black text-[15px] font-normal ">
                  <CircularProgress color="secondary" />
                </div>
              ) : (
                <div className="text-black text-[15px] font-normal ml-[15px]">
                  一致する結果はありません
                </div>
              )}
            </div>
          </>
        )}
      </div>

      <DeleteUserConfirm
        open={confirmDeleteOpen}
        onClose={() => {
          setConfirmDeleteOpen(false);
          openModal(false);
          setUserId();
        }}
        onConfirm={() => handleDelete()}
        numberUser={userIds?.length ?? 1}
      />
      <UserRegistration
        open={modalRegistOpen}
        onClose={() => handleCloseDialog()}
        onSubmit={() => handleCreate()}
        numberUser={users?.items?.data?.users.length}
      />
      <DialogCompleted
        open={successDialogOpen}
        onClose={() => {
          setSuccessDialogOpen(false);
          openModal(false);
        }}
        message={messageSuccess}
      />
      <UserDetail
        open={modalDetailOpen}
        onClose={() => {
          setModalDetailOpen(false);
          openModal(false);
        }}
        onOpenConfirmDelete={() => {
          setModalDetailOpen(false);
          setConfirmDeleteOpen(true);
          openModal(true);
        }}
        onReject={() => handleUpdate()}
        onApprove={() => handleUpdate()}
        onUpdate={() => handleUpdate()}
        id={userId}
      />
      {users.items && users.items.data && users.items.data.users.length > 0 && (
        <div className="relative flex justify-between mt-[17px] mr-[5px]">
          <div className="flex items-center">
            <div className="w-[38px] h-3 text-zinc-500 text-[11px] font-medium  leading-3">
              表示数
            </div>
            <select
              id="limit"
              defaultValue={currentLimit}
              className="w-[69px] h-[23px] bg-white rounded-sm border border-stone-300 ml-[5px] mr-[5px] focus:ring-blue-500 focus:border-blue-500 text-center text-sm cursor-pointer"
              onChange={onChangeLimit}
            >
              {optionLimitPage.map((item, index) => {
                return (
                  <option key={index} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            <div className="w-44 h-3 text-zinc-500 text-[11px] font-medium  leading-3">
              全 {users.items.data.totalRows} 件中 /{" "}
              {currentPage * currentLimit + 1}-
              {(currentPage + 1) * currentLimit > users.items.data.totalRows &&
              (users.items.data.totalPages == 1 ||
                currentPage + 1 == users.items.data.totalPages)
                ? users.items.data.totalRows
                : (currentPage + 1) * currentLimit}
              件目を表示
            </div>
          </div>

          <ReactPaginate
            breakClassName="pb-[10px]"
            containerClassName=" flex items-center justify-center"
            previousLabel={
              <span className="w-3 h-3 flex items-center justify-center rounded-md">
                <BsArrowLeft />
              </span>
            }
            nextLabel={
              <span className="w-3 h-3 flex items-center justify-center rounded-md">
                <BsArrowRight />
              </span>
            }
            pageClassName="block border-solid hover:rounded-full hover:bg-gray-100 w-5 h-5 text-center text-zinc-800 text-[12px] font-bold  leading-3 flex items-center justify-center rounded-md mx-[3px]"
            onPageChange={handlePageClick}
            pageRangeDisplayed={10}
            marginPagesDisplayed={0}
            activeClassName="block border-solid rounded-md rounded-full bg-gray-100 w-5 h-5"
            pageCount={users.items.data.totalPages}
            breakLabel="..."
            renderOnZeroPageCount={null}
            forcePage={currentPage}
            pageLinkClassName="text-[12px]"
          ></ReactPaginate>
        </div>
      )}
    </>
  );
}
