import React, { Fragment } from "react";
import { useDispatch, useSelector } from "react-redux";
import MenuBar from "../../../components/MenuBar";
import PageHeader from "../../../components/PageHeader";
import { supplierActions } from "../../../actions/supplier.actions";
import SupplierManagementTab from "./SupplierManagementTab";
import Moment from "moment";
import ReactPaginate from "react-paginate";
import { BsArrowLeft, BsArrowRight } from "react-icons/bs";
import SupplierRegistration from "./SupplierRegistration";
import SupplierDetail from "./SupplierDetail";
import DialogCompleted from "../../../components/DialogCompleted";
// import DeleteUserConfirm from "../user/DeleteUserConfirm";
import DeleteSupplierConfirm from "../supplier/DeleteSupplierConfirm";
import { useNavigate, useParams, useLocation } from "react-router-dom";
import SupplierLocation from "./SupplierLocation";
import { Tooltip } from "@mui/material";
import polygon2 from "../../../assets/polygon2.png";
import polygon1 from "../../../assets/polygon1.png";
import CircularProgress from "@mui/material/CircularProgress";
import classNames from "classnames";
import $ from "jquery";

export default function SupplierManagementList() {
  const { id } = useParams();
  let location = useLocation();

  const dispatch = useDispatch();

  const [supplierId, setSupplierId] = React.useState();
  const [modalDetailOpen, setModalDetailOpen] = React.useState(false);
  const [modalRegistOpen, setModalRegistOpen] = React.useState(false);
  const [successDialogOpen, setSuccessDialogOpen] = React.useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = React.useState(false);
  const [currentPage, setCurrentPage] = React.useState(
    location?.state?.page !== undefined ? location?.state?.page : 0
  );
  const [currentLimit, setCurrentLimit] = React.useState(10);
  const [searchValue, setSearchValue] = React.useState("");
  const [supplierIds, setSupplierIds] = React.useState([]);
  const [messageSuccess, setMessageSuccess] = React.useState("");
  const [isOpen, setIsOpen] = React.useState(false);
  const [latitude, setLatitude] = React.useState(35.6812405);
  const [longtitude, setLongtitude] = React.useState(139.7646955);
  const [modalLocationOpen, setModalLocationOpen] = React.useState(false);
  const navigate = useNavigate();
  const suppliers = useSelector((state) => state.supplier.suppliers);
  const [suppliersSort, setSuppliersSort] = React.useState([]);
  const [address, setAddress] = React.useState("");
  const [postalCode, setPostalCode] = React.useState("");
  const [detailPosition, setDetailPosition] = React.useState({
    lat: 0,
    lng: 0,
  });
  const [sortField, setSortFiels] = React.useState({
    id: 1,
    name: 1,
    address_detail: 1,
    detail: 1,
    created_at: 1,
    company_phone_number: 1,
    admin_id: 1,
  });

  // var body = {
  //   number_of_records_per_page: 10,
  //   page_number: 1,
  //   orderBy: "id",
  //   direction: "DESC",
  // };

  const [body, setBody] = React.useState({
    number_of_records_per_page: 10,
    page_number: currentPage + 1,
    orderBy: "id",
    direction: "DESC",
  });

  React.useEffect(() => {
    if (searchValue == undefined) {
      searchValue == "";
    }
    setSupplierIds([]);
    dispatch(supplierActions.getSuppliers(searchValue, body));
  }, []);

  React.useEffect(() => {
    setSupplierIds([]);
    dispatch(supplierActions.getSuppliers(searchValue, body));
  }, [body, searchValue]);

  React.useEffect(() => {
    if (id !== undefined) {
      setDetailPosition({
        lat: location?.state?.lat,
        lng: location?.state?.long,
      });
      handleOpenDetail(id);
    }
  }, []);

  React.useEffect(() => {}, [suppliers.items]);

  // const callApiGetSupplier = () => {
  //   debugger;
  //   dispatch(supplierActions.getSuppliers(searchValue, body));
  // };

  const handleCloseDialog = () => {
    setModalRegistOpen(false);
    setIsOpen(false);
  };

  const handleDeleteDialogOpen = () => {
    if (supplierIds.length > 0) {
      setConfirmDeleteOpen(true);
      setIsOpen(true);
    }
  };

  const handleDelete = async () => {
    if (supplierId) {
      var data = {
        ids: [supplierId],
      };
    } else if (supplierIds.length > 0) {
      var data = {
        ids: supplierIds,
      };
    }

    await dispatch(supplierActions.deleteItem(data));
    setConfirmDeleteOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess(data.ids.length + "件の仕入先削除が\n完了しました");
    setIsOpen(true);
    setSupplierId();
    setTimeout(() => {
      setSupplierIds([]);
    }, 3000);
    handleSearch("");
  };

  const handleCreate = () => {
    handleSearch("");
    setModalRegistOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess("1件の仕入先登録が\n完了しました");
    setIsOpen(true);
  };

  const handleUpdate = () => {
    handleSearch("");
    setModalDetailOpen(false);
    setSuccessDialogOpen(true);
    setMessageSuccess("1件の仕入先編集が\n完了しました");
    setIsOpen(true);
  };

  const handleOpenDetail = (id) => {
    setSupplierId(id);
    setModalDetailOpen(true);
    setIsOpen(true);
  };

  const handleSearch = (value) => {
    // body.number_of_records_per_page = currentLimit;
    setBody({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: 1,
    });
    setCurrentPage(0);
    // dispatch(supplierActions.getSuppliers(value, body));
  };

  const onChangeSearchValue = (event) => {
    setSearchValue(event.target.value);
    handleSearch(event.target.value);
  };

  const handleChangeValue = (e) => {
    if (e.target.checked) {
      setSupplierIds([...supplierIds, e.target.value]);
    } else {
      var array = [...supplierIds];
      var index = array.indexOf(e.target.value);
      if (index !== -1) {
        array.splice(index, 1);
        setSupplierIds(array);
      }
    }
  };

  const handlePageClick = (event) => {
    setCurrentPage(event.selected);
    // var body = {
    //   number_of_records_per_page: currentLimit,
    //   page_number: event.selected + 1,
    // };
    // body.number_of_records_per_page = currentLimit;
    // body.page_number = event.selected + 1;
    setBody((body) => ({
      ...body,
      number_of_records_per_page: currentLimit,
      page_number: event.selected + 1,
    }));
    // dispatch(supplierActions.getSuppliers(searchValue, body));
  };

  const onChangeLimit = (event) => {
    setCurrentLimit(event.target.value);
    setCurrentPage(0);
    // var body = {
    //   number_of_records_per_page: event.target.value,
    //   page_number: 1,
    // };
    // body.number_of_records_per_page = event.target.value;
    // body.page_number = 1;
    setBody((body) => ({
      ...body,
      number_of_records_per_page: event.target.value,
      page_number: 1,
    }));
    // dispatch(supplierActions.getSuppliers(searchValue, body));
  };

  const optionLimitPage = [
    { value: "10", label: "10" },
    { value: "50", label: "50" },
    { value: "100", label: "100" },
    { value: "1000", label: "1000" },
  ];

  const handleOpenRegisterd = (e) => {
    e.preventDefault();
    setModalRegistOpen(true);
    setIsOpen(true);
  };

  const handleBatchRegist = (e) => {
    e.preventDefault();
    navigate("/batchregistration");
  };

  const handleSelectLocation = (
    latValue,
    longValue,
    addressArr,
    postalCode
  ) => {
    setDetailPosition({
      lat: latValue,
      lng: longValue,
    });
    setLatitude(latValue);
    setLongtitude(longValue);
    setModalLocationOpen(false);
    // setPostalCode(postalCode);
  };

  const handleSelectAddress = (latitude, longtitude, address, postalCode) => {
    setAddress(address);
    setLatitude(latitude);
    setLongtitude(longtitude);
    setPostalCode(postalCode);
  };

  const handlePinLocation = (latitude, longtitude) => {
    setDetailPosition({
      lat: latitude,
      lng: longtitude,
    });
  };

  const handleSort = (field) => {
    let direction;
    if (field === "id") {
      sortField.id == 0 ? (sortField.id = 1) : (sortField.id = 0);
      sortField.id == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field === "admin_id") {
      sortField.admin_id == 0
        ? (sortField.admin_id = 1)
        : (sortField.admin_id = 0);
      sortField.admin_id == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field === "name") {
      sortField.name == 0 ? (sortField.name = 1) : (sortField.name = 0);
      sortField.name == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field === "address_detail") {
      sortField.address_detail == 0;
      sortField.address_detail == 0
        ? (sortField.address_detail = 1)
        : (sortField.address_detail = 0);
      sortField.address_detail == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field === "company_phone_number") {
      sortField.company_phone_number == 0
        ? (sortField.company_phone_number = 1)
        : (sortField.company_phone_number = 0);
      sortField.company_phone_number == 0
        ? (direction = "ASC")
        : (direction = "DESC");
    } else if (field === "detail_category") {
      sortField.detail == 0 ? (sortField.detail = 1) : (sortField.detail = 0);
      sortField.detail == 0 ? (direction = "ASC") : (direction = "DESC");
    } else if (field === "created_at") {
      sortField.created_at == 0
        ? (sortField.created_at = 1)
        : (sortField.created_at = 0);
      sortField.created_at == 0 ? (direction = "ASC") : (direction = "DESC");
    }

    setSortFiels(sortField);
    setBody((body) => ({
      ...body,
      orderBy: field,
      direction: direction,
    }));
  };

  $(function () {
    $("#limit").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
    $("#limit option").each(function () {
      this.style.setProperty("color", "#000", "important");
    });
  });
  $("select").on("change", function () {
    if ($(this).val() == "") $(this).css({ color: "#aaa" });
    else $(this).css({ color: "#000" });
  });

  return (
    <>
      <div>
        <div
          className={isOpen ? "opacity-25 fixed inset-0 z-40 bg-black" : ""}
        ></div>
        <PageHeader />
        <div className="mt-[10px] flex flex-row h-screen">
          <MenuBar />
          <div className="lg:w-[80%] pl-[2%] xl:w-[80%] 2xl:w-[80%] md:w-[80%] lg:text-xs">
            <div className="text-black text-xl font-medium ">仕入先管理</div>
            <div className="2xl:w-full h-px bg-zinc-300 mt-[8px]" />
            <div className="mt-[25px] text-sm font-medium text-center text-gray-500 dark:text-gray-400">
              <ul className="flex flex-wrap -mb-px">
                <SupplierManagementTab />
              </ul>
            </div>
            <div className="relative flex flex-row 2xl:w-full h-[75px] bg-zinc-100 justify-between">
              <div>
                <button
                  type="button"
                  onClick={(e) => handleOpenRegisterd(e)}
                  className="w-[81px] h-6 mt-[29px] ml-[10px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300 cursor pointer"
                >
                  新規登録
                </button>
                <button
                  disabled={supplierIds.length <= 0}
                  onClick={() => handleDeleteDialogOpen(true)}
                  className={`${
                    supplierIds.length > 0
                      ? "border-blue-900 text-blue-900"
                      : "border-gray-300 text-gray-300"
                  } w-[81px] h-6 mt-[29px] ml-[9px] bg-white rounded-[50px] border text-center text-xs font-bold hover:bg-violet-300 active:bg-violet-300 focus:outline-none focus:ring focus:ring-violet-300`}
                >
                  一括削除
                </button>
              </div>

              {/* <button
                onClick={(e) => handleBatchRegist(e)}
                className="border-blue-900 text-blue-900 w-[81px] h-6 mt-[29px] ml-[9px] bg-white rounded-[50px] border text-center text-xs font-bold hover:bg-violet-200 active:bg-violet-200 focus:outline-none focus:ring focus:ring-violet-300"
              >
                一括登録
              </button> */}

              <div className="relative flex flex-col 2xl:w-[50%] xl:w-[50%] lg:w-[50%] h-[51px] mt-[9px] ">
                <span className="text-black text-sm font-normal ">
                  キーワード検索
                </span>
                <div className="2xl:w-full xl:w-full lg:w-full">
                  <input
                    placeholder="キーワードを入力…"
                    className="p-2 w-full h-[27px] bg-white rounded-[5px] text-black/opacity-60 text-xs font-normal"
                    value={searchValue}
                    onChange={onChangeSearchValue}
                  />
                  <button
                    onClick={(e) => {
                      handleSearch(searchValue);
                    }}
                    className="absolute right-[0px] top-[24px] w-[61px] h-[18px] bg-blue-900 rounded-[50px] border border-blue-900 text-center text-white text-xs font-bold hover:bg-violet-600 active:bg-violet-700 focus:outline-none focus:ring focus:ring-violet-300"
                  >
                    検索
                  </button>
                </div>
              </div>
            </div>

            <div className="relative flex 2xl:w-full xl:w-full h-[35px] mt-[16px] bg-zinc-100 items-center">
              <div className="flex items-center lg:w-[8%] xl:w-[8%] 2xl:w-[8%] justify-center">
                <span className="">#</span>
                <img
                  className="w-[9px] h-[9px] 2xl:ml-[10px]"
                  src={sortField.id == 1 ? polygon1 : polygon2}
                  onClick={() => {
                    handleSort("id");
                  }}
                />
              </div>
              <div className="flex lg:w-[15%] xl:w-[15%] 2xl:w-[15%] items-center justify-start 2xl:pl-[20px] xl:pl-[15px] lg:pl-[8px]">
                <span className="text-center text-black text-sm font-normal ">
                  仕入先名
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={sortField.name == 1 ? polygon1 : polygon2}
                  onClick={() => handleSort("name")}
                />
              </div>
              <div className="flex items-center justify-start lg:w-[8%] xl:w-[8%] 2xl:w-[8%]">
                <span className=" text-center text-black text-sm font-normal ">
                  登録者ID
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={sortField.admin_id == 1 ? polygon1 : polygon2}
                  onClick={() => handleSort("admin_id")}
                />
              </div>
              <div className="flex items-center justify-start lg:w-[22%] xl:w-[22%] 2xl:w-[22%]">
                <span className=" text-center text-black text-sm font-normal ">
                  所在地
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={sortField.address_detail == 1 ? polygon1 : polygon2}
                  onClick={() => handleSort("address_detail")}
                />
              </div>

              <div className="flex lg:w-[16%] xl:w-[16%] 2xl:w-[16%] justify-start items-center ">
                <span className=" text-center text-black text-sm font-normal ">
                  企業電話番号
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={
                    sortField.company_phone_number == 1 ? polygon1 : polygon2
                  }
                  onClick={() => handleSort("company_phone_number")}
                />
              </div>
              <div className="flex lg:w-[16%] xl:w-[16%] 2xl:w-[16%] items-center justify-start">
                <span className=" text-center text-black text-sm font-normal ">
                  取扱品目
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={sortField.detail == 1 ? polygon1 : polygon2}
                  onClick={() => handleSort("detail_category")}
                />
              </div>
              <div className="flex xl:text-sm lg:text-sm lg:w-[13%] xl:w-[13%] 2xl:w-[10%] items-center justify-start">
                <span className="text-center text-black text-sm font-normal ">
                  仕入先登録日
                </span>
                <img
                  className="w-[10px] h-[10px] ml-[6%]"
                  src={sortField.created_at == 1 ? polygon1 : polygon2}
                  onClick={() => handleSort("created_at")}
                />
              </div>
            </div>
            <div
              className={classNames(
                "flex flex-col xl:w-full 2xl:w-full 2xl:h-[80%] xl:h-[50%] lg:h-[50%] 2xl:max-h-[541px]",
                {
                  "overflow-y-scroll":
                    suppliers.items && suppliers.items.suppliers.length > 0,
                }
              )}
            >
              {suppliers.items && suppliers.items.suppliers.length > 0 ? (
                suppliers.items.suppliers.map((item, i) => {
                  var phone = "" + item.company_phone_number;
                  var formated_phone =
                    phone.substring(0, 3) +
                    "-" +
                    phone.substring(3, 7) +
                    "-" +
                    phone.substring(7, 11) +
                    phone.substring(11, phone.length);

                  var categoryDetail = item.supplier_products
                    .map(
                      (cat) =>
                        (cat?.big_category?.name
                          ? cat?.big_category?.name
                          : "") +
                        (cat?.medium_category?.name
                          ? "・" + cat?.medium_category?.name
                          : "") +
                        (cat?.detail_category?.name
                          ? "・" + cat?.detail_category?.name
                          : "")
                    )
                    .join("\n");
                  return (
                    <Fragment key={item.id + "_" + i}>
                      <div className="relative flex lg:w-full xl:w-full 2xl:w-full 2xl:h-[72px] xl:h-[72px] lg:h-[72px] items-center border-b-[1px] border-zinc-300">
                        <div className="flex justify-end lg:w-[3%] xl:w-[3%] 2xl:w-[3%] 2xl:pr-[10px]">
                          <input
                            className=" w-3.5 h-3.5"
                            type="checkbox"
                            value={item.id}
                            onChange={(e) => handleChangeValue(e)}
                          />
                        </div>
                        <div
                          className=" flex lg:w-[97%] xl:w-[97%] 2xl:w-[97%] h-[72px] items-center cursor-pointer"
                          onClick={() => {
                            handleOpenDetail(item.id);
                            setLatitude(item.latitude);
                            setLongtitude(item.longtitude);
                            setDetailPosition({
                              lat: item.latitude,
                              lng: item.longtitude,
                            });
                          }}
                        >
                          <div className=" lg:w-[6.5%] xl:w-[6.5%] 2xl:w-[6.5%] text-zinc-800 text-sm font-normal leading-3 truncate">
                            <Tooltip title={"100" + item.id} arrow>
                              <span>{"100" + item.id}</span>
                            </Tooltip>
                          </div>
                          <div className=" lg:w-[14.5%] xl:w-[14.5%] 2xl:w-[14.5%] text-black text-sm font-normal truncate ">
                            <Tooltip title={item.name} arrow>
                              <span>
                                {/* {item.name.length <= 20
                                  ? item.name
                                  : item.name.substring(0, 20) + "..."} */}
                                {item.name}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="lg:w-[8.2%] xl:w-[8.2%] 2xl:w-[8.2%] text-black text-sm font-normal truncate">
                            {"100" + item.admin_id}
                          </div>
                          <div className="lg:w-[23.5%] xl:w-[23.2%] 2xl:w-[23%] text-black text-sm font-normal truncate pr-[10px]">
                            <Tooltip title={item.address_detail} arrow>
                              <span>
                                {/* {item.address_detail.length <= 15
                                  ? item.address_detail
                                  : item.address_detail.substring(0, 15) +
                                    "..."} */}
                                {item.address_detail}
                              </span>
                            </Tooltip>
                          </div>
                          <div className="lg:w-[16.8%] xl:w-[16.8%] 2xl:w-[16.8%] text-black text-sm font-normal truncate">
                            {/* {item.company_phone_number?.replace(
                              /(\d{2})(\d{4})(\d{4})/,
                              "$1-$2-$3"
                            )} */}
                            {item.company_phone_number}
                          </div>
                          <div className="lg:w-[17%] xl:w-[17%] 2xl:w-[16.8%] text-black text-sm font-normal truncate ">
                            <Tooltip
                              title={
                                <span style={{ whiteSpace: "pre-line" }}>
                                  {categoryDetail}
                                </span>
                              }
                              arrow
                            >
                              <span>
                                {categoryDetail?.length <= 10
                                  ? categoryDetail
                                  : categoryDetail?.substring(0, 10) + "..."}
                              </span>
                            </Tooltip>
                          </div>
                          <div className=" lg:w-[10%] xl:w-[10%] 2xl:w-[10%] text-black text-sm font-normal ">
                            <Tooltip
                              title={
                                item.created_at
                                  ? Moment(item.created_at).format("YYYY/MM/DD")
                                  : ""
                              }
                              arrow
                            >
                              <span>
                                {item.created_at
                                  ? Moment(item.created_at).format("YYYY/MM/DD")
                                  : ""}
                              </span>
                            </Tooltip>
                          </div>
                          <button
                            onClick={() => {
                              handleOpenDetail(item.id);
                              setDetailPosition({
                                lat: item.latitude,
                                lng: item.longtitude,
                              });
                            }}
                            href="#"
                            className=" text-blue-900 text-sm font-normal "
                          >
                            詳細
                          </button>
                        </div>
                      </div>
                    </Fragment>
                  );
                })
              ) : (
                <>
                  <div className="flex 2xl:w-full xl:w-full lg:w-full h-full justify-center items-center">
                    {suppliers.loading ? (
                      <div className="text-black text-[15px] font-normal ">
                        <CircularProgress color="secondary" />
                      </div>
                    ) : (
                      <div className="top-[400px] text-black text-[15px] font-normal ml-[15px]">
                        一致する結果はありません
                      </div>
                    )}
                  </div>
                </>
              )}
            </div>

            <DeleteSupplierConfirm
              open={confirmDeleteOpen}
              onClose={() => {
                setConfirmDeleteOpen(false);
                setIsOpen(false);
                setSupplierId();
              }}
              onConfirm={() => handleDelete()}
              numberUser={supplierIds?.length ?? 1}
            />
            <SupplierRegistration
              open={modalRegistOpen}
              onClose={() => handleCloseDialog()}
              onSubmit={() => handleCreate()}
              openLocation={() => setModalLocationOpen(true)}
              onInputAddress={handleSelectAddress}
              onPinLocation={handlePinLocation}
              latitude={latitude}
              longtitude={longtitude}
              address={address}
              postalCode={postalCode}
            />
            <DialogCompleted
              open={successDialogOpen}
              onClose={() => {
                setSuccessDialogOpen(false);
                setIsOpen(false);
              }}
              message={messageSuccess}
            />
            <SupplierDetail
              open={modalDetailOpen}
              onClose={() => {
                setModalDetailOpen(false);
                setIsOpen(false);
              }}
              onOpenConfirmDelete={() => {
                setModalDetailOpen(false);
                setConfirmDeleteOpen(true);
                setIsOpen(true);
              }}
              onUpdate={() => {
                handleUpdate();
              }}
              id={supplierId}
              openLocation={(latitude, longtitude) => {
                setLatitude(latitude);
                setLongtitude(longtitude);
                setModalLocationOpen(true);
              }}
              latitude={detailPosition.lat}
              longtitude={detailPosition.lng}
              address={address}
              postalCode={postalCode}
              onPinLocation={handlePinLocation}
            />
            <SupplierLocation
              open={modalLocationOpen}
              onClose={() => {
                setModalLocationOpen(false);
              }}
              onConfirm={handleSelectLocation}
              latitude={detailPosition.lat}
              longtitude={detailPosition.lng}
            />
            {suppliers.items && suppliers.items.suppliers.length > 0 && (
              <div className="relative flex justify-between mt-[17px] mr-[5px]">
                <div className="flex items-center">
                  <div className="w-[38px] h-3 text-zinc-500 text-[11px] font-medium  leading-3">
                    表示数
                  </div>
                  <select
                    id="limit"
                    defaultValue={currentLimit}
                    className="w-[69px] h-[23px] bg-white rounded-sm border border-stone-300 ml-[5px] mr-[5px] focus:ring-blue-500 focus:border-blue-500 text-center text-sm cursor-pointer"
                    onChange={onChangeLimit}
                  >
                    {optionLimitPage.map((item, index) => {
                      return (
                        <option key={index} value={item.value}>
                          {item.label}
                        </option>
                      );
                    })}
                  </select>
                  <div className="w-44 h-3 text-zinc-500 text-[11px] font-medium  leading-3">
                    全 {suppliers.items.totalRows} 件中 /{" "}
                    {currentPage * currentLimit + 1}-
                    {(currentPage + 1) * currentLimit >
                      suppliers.items.totalRows &&
                    (suppliers.items.totalPages == 1 ||
                      currentPage + 1 == suppliers.items.totalPages)
                      ? suppliers.items.totalRows
                      : (currentPage + 1) * currentLimit}
                    件目を表示
                  </div>
                </div>

                <ReactPaginate
                  breakClassName="pb-[8px] mr-[5px]"
                  containerClassName=" flex items-center justify-center"
                  previousLabel={
                    <span className="w-3 h-3 flex items-center justify-center rounded-md">
                      <BsArrowLeft />
                    </span>
                  }
                  nextLabel={
                    <span className="w-3 h-3 flex items-center justify-center rounded-md">
                      <BsArrowRight />
                    </span>
                  }
                  pageClassName="block border-solid hover:rounded-full hover:bg-gray-100 w-5 h-5 text-center text-zinc-800 text-[12px] font-bold  leading-3 flex items-center justify-center rounded-md mx-[3px]"
                  onPageChange={handlePageClick}
                  pageRangeDisplayed={10}
                  marginPagesDisplayed={0}
                  activeClassName="block border-solid rounded-md rounded-full bg-gray-100 w-5 h-5"
                  pageCount={suppliers.items.totalPages}
                  breakLabel="..."
                  renderOnZeroPageCount={null}
                  forcePage={currentPage}
                  pageLinkClassName="text-[12px]"
                ></ReactPaginate>
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
}
